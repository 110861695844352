import cx from 'classnames'
import Loader from './Loader'
import s from './ActionButton.sass'

export default ({ icon, label, big, secondary, destructive, loading, type = 'button', className, ...props }) => {
  const Element = props.href ? 'a' : 'button'

  return (
    <Element
      class={cx(s.button, big && s.big, secondary && s.secondary, destructive && s.destructive, loading && s.loading, className)}
      type={type}
      {...props}
    >
      {icon && <img src={require(`../images/${icon}.svg`).default} />}
      {loading && <span class={s.loader}><Loader showImmediately /></span>}
      <span class={s.label}>{label}</span>
    </Element>
  )
}
