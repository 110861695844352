import s from './Loader.sass'
import cx from 'classnames'

export default ({ large, mod, showImmediately }) => {
  if (!mod) mod = large ? 2 : 1

  return (
    <svg
      width={mod * 15}
      height={mod * 26}
      viewBox='0 0 15 26'
      class={cx(s.loader, showImmediately && s.showImmediately)}
    >
      <path class={s.one} d='M8.806 6.26H15V13H8.806C3.943 13 0 9.077 0 4.237V0h6.774v4.237c0 1.117.91 2.022 2.032 2.022z' />
      <path class={s.two} d='M8.806 6.26H15V13H8.806C3.943 13 0 9.077 0 4.237V0h6.774v4.237c0 1.117.91 2.022 2.032 2.022z' />
    </svg>
  )
}
