import createStore from 'unistore'

const defaultStore = () => ({
  hamburgerOpen: false,
  teams: {},
  presentations: {},
  cachedResponses: {},
  toasts: []
})

const store = createStore(defaultStore())

export const clearStore = () => store.setState(defaultStore())

export default store
