/* global location */

import useCookieValue from '../hooks/useCookieValue'
import ActionButton from './ActionButton'
import extractNakedDomain from '../utils/extractNakedDomain'
import cx from 'classnames'
import s from './CookiesConsent.sass'

export default ({ positioned, handled }) => {
  const host = extractNakedDomain(typeof location !== 'undefined' && location.hostname)
  const [hasDismissed, setHasDismissed] = useCookieValue('cookies-accepted', 'boolean', host)
  const visible = handled ? !hasDismissed : true

  return (
    <div class={cx(s.wrapper, positioned && s.positioned, visible && s.visible)}>
      <div class={s.bar}>
        <p class={s.cookie}>🍪</p>
        <p class={s.text}><strong>Can we store cookies?</strong> These will be used to create the best site experience possible for you.</p>
        <ActionButton label='Read more' href='https://www.trupp.io/r/privacypolicy' target='_blank' secondary className={s.button} />
        <ActionButton label="Yes, that's okay" className={s.button} onClick={() => setHasDismissed(true)} />
      </div>
    </div>
  )
}
