export default () => {
  if (typeof window !== 'undefined') {
    const [,, subdomain] = window.location.hostname.split('.').reverse()

    if (subdomain && subdomain !== 'www' && subdomain !== 'staging--trupp') {
      return subdomain
    }
  }

  return null
}
