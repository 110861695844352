export default (domain) => {
  if (!domain) return undefined
  if (domain.includes('netlify.app')) return domain

  const parts = domain.split('.')

  if (parts.length <= 1) return domain
  if (parts.length <= 2) return '.' + domain

  return '.' + parts.slice(1).join('.')
}
