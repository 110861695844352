/* global location */

import { Provider } from 'unistore/preact'
import Router from 'preact-router'
import store from './store'
import Landing from './routes/Landing'
import Dashboard from './routes/Dashboard'
import Components from './routes/Components'
import Login from './routes/Login'
import Presentation from './routes/Presentation'
import NotFound from './routes/NotFound'
import RedirectTo from './components/RedirectTo'
import CookiesConsent from './components/CookiesConsent'
import getTeamFromSubdomain from './utils/getTeamFromSubdomain'
import './index.sass'

// if (process.env.NODE_ENV !== 'development' && typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
//   // eslint-disable-next-line
//   navigator.serviceWorker.register(__webpack_public_path__ + 'sw.js')
// }

if (typeof document !== 'undefined') {
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse')
  })
  document.body.addEventListener('keydown', (ev) => {
    if (ev.key === 'Tab') {
      document.body.classList.remove('using-mouse')
    }
  })
}

const teamFromSubdomain = getTeamFromSubdomain()

export default () => (
  <div id='app'>
    <Provider store={store}>
      {
        teamFromSubdomain
          ? (
            <Router>
              <RedirectTo teamAlias={teamFromSubdomain} path='/' to='https://www.trupp.io' />
              <Presentation path='/:deckId/:memberId?/:caseId?' teamAlias={teamFromSubdomain} isFromSubdomain />
              <NotFound default />
            </Router>
          ) : (
            <Router>
              <Landing path='/' />
              <Landing path='/r/confirm' confirmPage />
              <Login path='/r/login' />
              <Presentation path='/r/presentation/:teamAlias/:deckId/:memberId?/:caseId?' />
              <RedirectTo path='/:teamAlias' to='/{teamAlias}/presentations' />
              <Dashboard path='/:teamAlias/:view/:id?/:subview?/:subviewid?' />
              <Components path='/__components' />
              <NotFound default />
            </Router>
          )
      }
    </Provider>
    {location.hash !== '#user-accepts-all-cookies' && (
      <CookiesConsent handled positioned />
    )}
  </div>
)
