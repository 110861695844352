import { useEffect } from 'preact/hooks'
import { route } from 'preact-router'

export default ({ teamAlias, to, matches }) => {
  if (typeof window !== 'undefined') {
    useEffect(() => {
      let result = to

      // Redirect to dashboard if teamAlias and no matches was found.
      if (teamAlias && Object.keys(matches).length === 0) {
        console.log('heeej')
        result += `/${teamAlias}`
      }

      for (const key of Object.keys(matches || {})) {
        result = result.replace(`{${key}}`, matches[key])
        console.log('result after key change', result)
      }

      if (result.indexOf('http') === 0) {
        window.location.href = result
      } else {
        route(result, true)
      }
    }, [])
  }

  return <div />
}
