import { useState, useEffect } from 'preact/hooks'
import Cookies from 'js-cookie'

const getValue = (name, type, domain) => {
  const value = Cookies.get(name, { domain })
  switch (type) {
    case 'boolean': return value === 'true'
    default: return value
  }
}

export default (name, type = 'boolean', domain) => {
  const [value, setValue] = useState(getValue(name, type, domain))

  useEffect(() => {
    Cookies.set(name, value + '', { expires: 365, domain })
  }, [value])

  return [value, setValue]
}
